<template>
  <BaseList
    :list-query="list"
    :completions-query="completions"
    route="artefact"
    locale-section="pages.artefacts"
  />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "ArtefactList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      list: gql`
        query artefacts {
          list: artefacts {
            name: artefact
            id
            artefact
          }
        }
      `,
      completions: gql`
        query findByArtefact($search: String!) {
          completions: findByArtefact(artefact: $search) {
            name: artefact
            id
          }
        }
      `
    };
  }
};
</script>
